@keyframes scaleIn {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes scaleOut {
  from {
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    transform: translate(-50%, -50%) scale(0);
  }
}

@keyframes shakeLeft {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shakeRight {
  0% {
    transform: translate(1px, 1px) rotate(0deg) scaleX(-1);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg) scaleX(-1);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg) scaleX(-1);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg) scaleX(-1);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg) scaleX(-1);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg) scaleX(-1);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg) scaleX(-1);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg) scaleX(-1);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg) scaleX(-1);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg) scaleX(-1);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg) scaleX(-1);
  }
}

.ripple {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: fixed;
  border-radius: 50%;
  border: 1px solid var(--color-light);
}

@keyframes ripple-effect {
  to {
    transform: scale(15);
    opacity: 0.01;
  }
}
