.des-popup {
  position: fixed;
  inset: 0;
  z-index: 100100000;

  .bg-dark {
    position: fixed;
    inset: 0;
    background-color: rgba($color-dark, 0.75);
    backdrop-filter: blur(5px);
  }

  .container {
    @include absCenter;
    width: 50vw;
    height: 50vh;
    background-color: var(--color-box);
    padding: 2rem;
    border-radius: 10px;
    overflow: hidden;

    

    .title {
      margin-bottom: 2rem;
    }
    input {
      height: 4rem;
      width: 50%;
      background-color: transparent;
      border: 2px solid var(--color-grey-light);
      padding: 0 1rem;
      color: inherit;
      outline: none;
      border-radius: 5px;
      margin-bottom: 3rem;

      &:focus {
        border: 2px solid var(--color-primary);
      }
    }

    .action {
      display: flex;
      align-items: center;

      .button-primary {
        border: 2px solid var(--color-primary);
        &:hover {
          border: 2px solid var(--color-tertiary);
        }
        &:first-child {
          background-color: transparent;
        }
        &:not(:last-child) {
          margin-right: 5rem;
        }
      }
    }
  }
}
