.room-add {
  padding: 1rem 2rem 1rem;
  width: 100%;
  position: absolute;
  top: 7.3rem;
  left: 0;
  background-color: var(--color-box);
  z-index: 999;
  border-bottom: 1px solid var(--color-grey);

  .header {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      //   width: 2rem;
      //   height: 2rem;
      border-radius: 50%;
      @include flexCenter;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
  .content {
    display: flex;

    .button-primary {
      margin-right: 1rem;
      width: fit-content;
      background-color: rgba($color-primary, 0.5);
      &:hover {
        background-color: rgba($color-primary, 0.75);
      }
    }

    .btn-active {
      background-color: var(--color-primary) !important;
    }
    .add {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      border: 2px solid var(--color-primary);
      @include flexCenter;
      cursor: pointer;
      flex-shrink: 0;

      &:hover {
        background-color: var(--color-primary);
        color: $color-light;
      }
    }

    .actions {
      width: fit-content;
      margin-left: auto;
      display: flex;
      .button-primary {
        background-color: var(--color-primary);

        &:hover {
          background-color: var(--color-tertiary);
        }
      }
      .menu-wrapper {
        position: relative;

        .menu {
          position: absolute;
          left: 50%;
          top: 4rem;
          transform: translateX(-75%);
          background-color: var(--color-box);
          width: 25rem;
          border-radius: 10px;
          border: 1px solid var(--color-grey);
          overflow: hidden;

          .user-info {
            padding: 2rem;
            border-bottom: 1px solid var(--color-grey);
            @include flexCenter;

            .wallet {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 0.5rem;
              .img {
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }

              p {
                font-size: 1.3rem;
                &:not(:last-child) {
                  margin-bottom: 1rem;
                }
              }
            }

            .wallet-id {
              color: var(--color-grey-light);
              font-size: 1.2rem;
            }
          }

          .menu-item {
            padding: 1.5rem 2rem;
            // border-radius: 5px;
            cursor: pointer;
            display: flex;
            align-items: center;
            i {
              margin-right: 1rem;
            }
            //   &:not(:last-child) {
            //     margin-bottom: 1rem;
            //   }
            &:hover {
              background-color: rgba($color-grey, 0.5);
              background-color: var(--color-primary);
              color: $color-light;
            }
          }
        }
      }
    }
  }

  .add-input {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    input {
      height: 4rem;
      width: 100%;
      margin-right: 2rem;
      background-color: transparent;
      border: 2px solid var(--color-grey-light);
      padding: 0 1rem;
      color: inherit;
      outline: none;
      border-radius: 5px;

      &:focus {
        border: 2px solid var(--color-primary);
      }
    }

    .check,
    .cross {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      @include flexCenter;
      flex-shrink: 0;
      cursor: pointer;
    }
    .check {
      background-color: var(--color-success);
      margin-right: 1rem;
    }
    .cross {
      background-color: var(--color-danger);
    }
  }
}
