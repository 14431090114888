.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 7.5rem;
  padding: 0 7.5rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-grey);
  user-select: none;
  z-index: 1000;
  background-color: var(--color-background);

  .left {
    flex: 1;
    display: flex;
    align-items: center;

    .logo {
      width: 20rem;
      margin-right: 5rem;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .middle {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-weight: bold;
      cursor: pointer;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .item-wrapper {
      position: relative;

      &:not(:last-child) {
        margin-right: 3rem;
      }
      .item {
        cursor: pointer;
        position: relative;
        p {
          font-weight: bold;
          &:before {
            content: "";
            position: absolute;
            bottom: -1rem;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background-color: var(--color-primary);
            transition: $transition-primary;
          }
        }

        i {
          font-size: 2rem;
          transition: $transition-primary;
          transform: perspective(1px) scale(1);
          backface-visibility: hidden;
        }

        &:hover {
          p {
            color: var(--color-primary);
            // &::before {
            //   width: 100%;
            // }
          }
          i {
            transform: perspective(1px) scale(1.2);
            color: var(--color-primary);
          }
        }
      }

      .item-active {
        p {
          &::before {
            width: 100%;
          }
        }
      }

      .menu {
        position: absolute;
        left: 50%;
        top: 4rem;
        transform: translateX(-75%);
        background-color: var(--color-box);
        width: 25rem;
        border-radius: 10px;
        border: 1px solid var(--color-grey);
        overflow: hidden;

        .user-info {
          padding: 2rem;
          border-bottom: 1px solid var(--color-grey);
          @include flexCenter;

          .wallet {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.5rem;
            .img {
              width: 2rem;
              height: 2rem;
              margin-right: 1rem;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            p {
              font-size: 1.3rem;
              &:not(:last-child) {
                margin-bottom: 1rem;
              }
            }
          }

          .wallet-id {
            color: var(--color-grey-light);
            font-size: 1.2rem;
          }
        }

        .menu-item {
          padding: 1.5rem 2rem;
          // border-radius: 5px;
          cursor: pointer;
          display: flex;
          align-items: center;
          i {
            margin-right: 1rem;
          }
          //   &:not(:last-child) {
          //     margin-bottom: 1rem;
          //   }
          &:hover {
            background-color: rgba($color-grey, 0.5);
            background-color: var(--color-primary);
            color: $color-light;
          }
        }
      }
    }
  }
}

.bg-menu {
  position: fixed;
  inset: 0;
}
