@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&family=Patrick+Hand+SC&display=swap");

// fonts
$font-primary: "Roboto", sans-serif;
$font-secondary: "Patrick Hand SC", cursive;

// colors

// hash values

$color-light: #fff;
$color-dark: #212529;
$color-background: #141315;
$color-box: #1a1a1a;
$color-primary: #ec4899;
$color-secondary: #7a4cbf;
$color-tertiary: #d6257d;
$color-success: #28a745;
$color-danger: #ff5000;
$color-warning: #282c2d;
$color-info: #986d8e;
$color-grey: #2a2e39;
$color-grey-dark: #2b2b2b;
$color-grey-light: #83858d;

// others
$transition-primary: all 0.3s;
$shadow-primary: rgba($color-grey, 0.15) 1.95px 1.95px 2.6px;
$shadow-secondary: rgba($color-dark, 0.1) 0px 4px 12px;
$shadow-secondary-light: rgba($color-light, 0.1) 0px 4px 12px;
$shadow-tertiary: rgba(0, 0, 0, 0.1) 0px 4px 12px;
$shadow-border-dark: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
$shadow-border-light: rgba(255, 255, 255, 0.16) 0px 1px 4px, rgb(255, 255, 255) 0px 0px 0px 3px;

:root {
  --color-light: #{$color-light};
  --color-dark: #{$color-dark};
  --color-background: #{$color-background};
  --color-box: #{$color-box};
  --color-primary: #{$color-primary};
  --color-secondary: #{$color-secondary};
  --color-tertiary: #{$color-tertiary};
  --color-success: #{$color-success};
  --color-danger: #{$color-danger};
  --color-warning: #{$color-warning};
  --color-info: #{$color-info};
  --color-grey: #{$color-grey};
  --color-grey-light: #{$color-grey-light};
  --color-grey-dark: #{$color-grey-dark};
  --color-dark-light-opaque: #{rgba($color-dark, 0.1)};
  --shadow-primary: #{$shadow-primary};
  --shadow-secondary: #{$shadow-secondary};
  --shadow-tertiary: #{$shadow-tertiary};
  --shadow-border: #{$shadow-border-dark};
}
