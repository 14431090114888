.change-ch-popup {
  position: fixed;
  inset: 0;
  z-index: 100100000;

  .bg-dark {
    position: fixed;
    inset: 0;
    background-color: rgba($color-dark, 0.75);
    backdrop-filter: blur(5px);
  }

  .container {
    @include absCenter;
    width: 50vw;
    height: 50vh;
    background-color: var(--color-box);
    padding: 2rem;
    border-radius: 10px;
    overflow: hidden;
    @include flexCenter;

    .title {
      margin-bottom: 2rem;
    }

    .arrow-left,
    .arrow-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      border: 2px solid var(--color-grey);
      @include flexCenter;
      transition: $transition-primary;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary);
        color: $color-light;
        border: 2px solid var(--color-primary);
      }
    }

    .arrow-left {
      left: 2rem;
    }
    .arrow-right {
      right: 2rem;
    }

    .fade-left,
    .fade-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 3rem;
    }

    .fade-left {
      left: 6rem;
      background: rgb(26, 26, 26);
      background: linear-gradient(270deg, rgba(26, 26, 26, 0) 0%, rgba(26, 26, 26, 1) 100%);
    }

    .fade-right {
      right: 6rem;
      background: rgb(26, 26, 26);
      background: linear-gradient(90deg, rgba(26, 26, 26, 0) 0%, rgba(26, 26, 26, 1) 100%);
    }

    .images {
      display: flex;
      align-items: center;
      width: 40vw;
      overflow-x: auto;
      @include hideScrollbar;
      scroll-behavior: smooth;

      .img {
        flex-shrink: 0;
        width: 20rem;
        height: 20rem;
        padding: 1rem;
        border: 2px solid var(--color-grey);
        border-radius: 10px;
        cursor: pointer;
        overflow: hidden;
        transition: $transition-primary;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          transition: $transition-primary;
        }

        &:hover {
          border: 2px solid var(--color-primary);

          //   img {
          //     transform: scale(1.2);
          //   }
        }

        &:not(:last-child) {
          margin-right: 2rem;
        }
      }
    }
  }
}
