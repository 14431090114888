.characters {
  position: relative;
  min-height: 100vh;

  .animate-left {
    // animation: shakeLeft 1s infinite !important;
  }

  .animate-right {
    // animation: shakeRight 1s infinite !important;
  }

  .ch-bg {
    @include absCenter;
    width: fit-content;
    height: 4rem;
  }

  .bg-menu-2 {
    position: fixed;
    inset: 0;
  }


  .ch3,
  .ch4 {
    z-index: 999;
  }

  .ch2,
  .ch4,
  .char2,
  .char4 {
    img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }

  .ch3,
  .ch4 {
    .placeholder {
      transform: translateY(9rem);
      .ring {
        // transform: translateY(5rem);
      }
    }
  }

  .ch1,
  .ch2,.ch5 {
    .placeholder {
      transform: translateY(3rem);
    }
  }

  .ch1,
  .ch2,
  .ch3,
  .ch4,.ch5 {
    position: absolute;

    video,
    .ring {
      width: 25rem;
      height: 25rem;
    }

    .char1,
    .char2,
    .char3,
    .char4 {
      width: 17.5vw;
      height: 17.5vw;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        min-width: 10rem;
        width: fit-content;
        background-color: rgba($color-primary, 0.75);
        position: absolute;
        left: 50%;
        top: -1rem;
        transform: translateX(-50%);
        border-radius: 5px;
        z-index: 2000;

        .heart {
          margin-right: 1rem;
        }

        p {
          margin-right: 1rem;
        }
        i {
          cursor: pointer;
        }

        .menu {
          position: absolute;
          right: -16rem;
          top: 0;
          min-width: 15rem;
          z-index: 30000;
          width: fit-content;
          height: fit-content;
          background-color: rgba($color-primary, 0.75);
          border-radius: 5px;
          overflow: hidden;
          .item {
            padding: 0.5rem 1rem;
            font-size: 1.3rem;

            &:hover {
              background-color: var(--color-primary);
              cursor: pointer;
            }
          }
        }
      }
    }

    .add {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      border: 2px solid var(--color-primary);
      @include flexCenter;
      cursor: pointer;
      flex-shrink: 0;
      position: absolute;
      left: 50%;
      top: -3rem;
      transform: translateX(-50%);
      z-index: 1;
      background-color: rgba($color-primary, 0.5);
      font-size: 2.5rem;

      &:hover {
        background-color: var(--color-primary);
        color: $color-light;
      }
    }
  }

  .char1,
  .char2 {
    margin-bottom: 5rem;

  }
  .char3,
  .char4 {
    margin-bottom: 0;
  }

  .ch1 {
    bottom: -5rem;
    left: 10vw;

  }

  .ch2 {
    bottom: -5rem;
    right: 10vw;
  }

  .ch3 {
    bottom: 28.5vh;
    left: 28%;
  }
  .ch4 {
    bottom: 28vh;
    right: 22%;
  }
  //centered between ch1 and ch2
  .ch5 {
    bottom: -5rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
