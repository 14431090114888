.landing {
  // background: url(../../media/images/bg1.png);
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-color: var(--color-box);
  // z-index: -1;
  padding: 0 7.5rem 0;
  height: 100vh;
  border: 1px solid var(--color-grey);
  position: relative;
  overflow: hidden;
  @import "./uis/RoomAdd/room-add";
  @import "./uis/Characters/characters";
  @import "./uis/ConnectWallet/connect-wallet";

  .background {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}
