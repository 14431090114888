.button-primary {
  width: 100%;
  height: 3rem;
  z-index: 51;
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 5px;
  user-select: none;
  transition: $transition-primary;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  min-width: 15rem;

  p {
    flex: 1;
    text-align: center;
    height: 100%;
    @include flexCenter;
  }

  .icon-right {
    margin-left: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    @include flexCenter;
    font-size: 1.2rem;
    line-height: 0;
    margin: 0;
    margin-right: 1rem;
    &:hover {
      background-color: rgba($color-grey, 0.5);
    }
  }

  &:hover {
    background-color: var(--color-tertiary);
  }
}
