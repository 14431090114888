* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-primary;
  font-size: 1.5rem;
  background-color: var(--color-background);
  color: var(--color-light);
  transition: $transition-primary;
  background-image: url(../../media/images/bg1.png);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

html {
  // This defines what 1rem is
  font-size: 56.25%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    // width < 1200?
    font-size: 40%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 40%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(phone) {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }
  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12, 12/16
  }
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}
